/* Base */

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Thin.eot');
	src: local('☺'), url('../fonts/Lato-Thin.woff') format('woff'), url('../fonts/Lato-Thin.ttf') format('truetype'), url('../fonts/Lato-Thin.svg') format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Light.eot');
	src: local('☺'), url('../fonts/Lato-Light.woff') format('woff'), url('../fonts/Lato-Light.ttf') format('truetype'), url('../fonts/Lato-Light.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Regular.eot');
	src: local('☺'), url('../fonts/Lato-Regular.woff') format('woff'), url('../fonts/Lato-Regular.ttf') format('truetype'), url('../fonts/Lato-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Semibold.eot');
	src: local('☺'), url('../fonts/Lato-Semibold.woff') format('woff'), url('../fonts/Lato-Semibold.ttf') format('truetype'), url('../fonts/Lato-Semibold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Bold.eot');
	src: local('☺'), url('../fonts/Lato-Bold.woff') format('woff'), url('../fonts/Lato-Bold.ttf') format('truetype'), url('../fonts/Lato-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Black.eot');
	src: local('☺'), url('../fonts/Lato-Black.woff') format('woff'), url('../fonts/Lato-Black.ttf') format('truetype'), url('../fonts/Lato-Black.svg') format('svg');
	font-weight: 800;
	font-style: normal;
}

* {
	outline: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

::-webkit-input-placeholder {color: $base_form_placeholder;}
::-moz-placeholder		{color: $base_form_placeholder;}
:-moz-placeholder		{color: $base_form_placeholder;}
:-ms-input-placeholder	{color: $base_form_placeholder;}

html, body {
	margin: 0;
	padding: 0;
	background: $base_background;
	font-size: $base_font_size;
	color: $base_text_color;
	font-family: $base_font_family, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

a {
	color: $base_link_color;
	text-decoration: none;
	cursor: pointer;
	&:link {
		color: $base_link_color;
	}
	&:active {
		color: $base_link_color;
	}
	&:visited {
		color: $base_link_color;
	}
	&:hover {
		text-decoration: underline; 
	}
	img {
		border: none;
	}
}

input, textarea, button {
	font-family: $base_font_family;
	-webkit-appearance: none;
	box-shadow: none;
}

label {
	cursor: pointer;
}

.clear {
	clear: both;
}

strong {
	font-weight: 700;
}

.centrize {
	display: table !important;
	table-layout: fixed !important;
	height: 100% !important;
	position: relative !important;
	&.full-width {
		width: 100% !important;
	}
}

.vertical-center {
	display: table-cell !important;
	vertical-align: middle !important;
}

.slimScrollRail {
	width: 2px!important;
	display: block!important;
	background: transparent!important;
	opacity: 1!important;
	right: 0!important;
}

.slimScrollBar {
	width: 4px!important;
	background: $base_text_color!important;
	border: none!important;
	cursor: pointer!important;
	opacity: 1!important;
	right: 0!important;
	@include border-radius(4px);
}

/*Buttons*/

a.btn, .btn {
	padding: 0 35px;
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 50px;
	line-height: 46px;
	font-size: 14px;
	color: $base_white_color;
	text-align: center;
	text-decoration: none;
	font-weight: 400;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	background: $base_hover_color;
	border: 2px solid $base_hover_color;
	@include border-radius(50px);
	@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
	@include transition(all 0.3s ease 0s);
	&.border {
		color: $base_hover_color;
		background: none;
		@include box-shadow(0 0 0 rgba(0,0,0,0.2));
		&:hover {
			color: $base_white_color;
			background: $base_hover_color;
			border-color: $base_hover_color;
		}
	}
	&:hover {
		@include box-shadow(0 0 0 rgba(0,0,0,0.2));
	}
}

a.btn.white, .btn.white {
	color: $base_link_color;
	background: $base_white_color;
	border: 2px solid $base_white_color;
	&.border {
		color: $base_white_color;
		background: none;
		&:hover {
			color: $base_white_color;
			background: $base_hover_color;
			border-color: $base_hover_color;
		}
	}
}

a.btn.small, .btn.small {
	height: 30px;
	line-height: 28px;
	font-size: 12px;
	border: 1px solid $base_hover_color;
}

/*Wrapper*/

.bg {
	position: relative;
	overflow: hidden;
}

.wrapper {
	padding-top: 190px;
	z-index: 1;
}

.fw {
	position: relative;
	margin: 0 auto;
	width: $base_width;
	&:after {
		content: '';
		clear: both;
		display: block;
	}
}

.disabled {display: none;}

.breadcrumbs {
	position: relative;
	margin: 0 0 10px 0;
	font-size: 0;
	z-index: 2;
	a, span {
		position: relative;
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		color: #31758c;
		font-weight: 400;
		line-height: 20px;
		border-right: none;
		text-decoration: none;
		border-bottom: 1px solid #31758c;
	}
	a {
		&:hover {
			border-bottom-color: transparent;
		}
	}
	span {
		border: none;
		color: #414141;
	}
	.sep {
		margin: 0 3px;
	}
}

/*Titles*/

.m-title {
	margin: 0 0 50px 0;
	font-size: 44px;
	line-height: 50px;
	font-weight: 700;
	color: #454545;
}

.h-title {
	margin: 0 0 35px 0;
	padding: 0 0 20px 0;
	font-size: 44px;
	line-height: 50px;
	font-weight: 700;
	color: #1a1a1a;
	border-bottom: 2px solid #31758c;
	span {
		margin-top: 5px;
		display: block;
		font-size: 30px;
		line-height: 30px;
		color: #808080;
	}
}

.m-subtitle {
	margin: 0 0 30px 0;
	font-size: 24px;
	line-height: 48px;
	font-weight: 600;
	color: #414141;
}

/*Text*/

.text-box {
	h1, h2, h3, h4, h5, h6 {
		margin: 0 0 20px 0;
		font-size: 30px;
		line-height: 30px;
		font-weight: 700;
		color: $base_text_color;
		&:first-child {
			margin-top: 0;
		}
	}
	p {
		margin: 20px 0;
		font-size: 18px;
		line-height: 28px;
		color: $base_text_color;
		font-weight: 400;
		strong {
			font-weight: 700;
		}
		img {
			margin: 20px 0;
			max-width: 100%;
			display: block;
			&.alignleft {
				float: left;
				max-width: 50%;
				margin-right: 30px;
			}
			&.alignright {
				float: right;
				max-width: 50%;
				margin-left: 30px;
			}
		}
		a {
			color: $base_link_color;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	blockquote {
		position: relative;
		margin: 20px 0;
		padding: 3px 3px 3px 13px;
		font-family: $base_font_family;
		font-size: 18px;
		line-height: 24px;
		color: $base_link_color;
		font-weight: 400;
		border-left: 3px solid $base_link_color;
	}
	ul {
		margin: 15px 0;
		padding: 0;
		list-style: none;
		li {
			position: relative;
			margin: 10px 0;
			padding: 0 0 0 35px;
			font-size: 18px;
			color: $base_text_color;
			line-height: 24px;
			font-weight: 400;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 9px;
				width: 9px;
				height: 9px;
				background: $base_link_color;
				@include border-radius(9px);
			}
		}
	}
	ol {
		margin: 15px 0;
		padding: 0;
		list-style: none;
		counter-reset: myCounter;
		li {
			position: relative;
			margin: 10px 0;
			padding: 0 0 0 35px;
			font-size: 18px;
			color: $base_text_color;
			line-height: 24px;
			font-weight: 400;
			&:before {
				content:counter(myCounter)'.';
				counter-increment: myCounter;
				position: absolute;
				left: 0;
				top: 0;
				width: auto;
				height: 24px;
				font-size: 18px;
				line-height: 24px;
				font-weight: 700;
				text-align: left;
				color: $base_link_color;
			}
		}
	}
	&.table {
		margin: 40px 0;
		position: relative;
		overflow: auto;
	}
	table {
		margin: 0;
		padding: 0;
		width: 1180px;
		border-collapse: collapse;
		tr {
			&:nth-child(odd) {
				td {
					background: #ebf6fa;
				}
			}
		}
		td {
			padding: 20px 70px;
			background: #d5e4e9;
			font-size: 18px;
			line-height: 24px;
			color: #333333;
			&:last-child {
				width: 350px;
			}
		}
	}
}

/*Preloader*/

.preloader {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: $base_white_color;
	z-index: 1000;
	.spinner {
		width: 64px;
		height: 64px;
		display: inline-block;
		position: relative;
		background: url(../images/preloader.gif) no-repeat;
		background-size: 100% 100%;
	}
}

/*Forms*/

.c-form {
	.cols {
		margin: 0 -10px;
		font-size: 0;
	}
	.col {
		padding: 0 10px;
		display: inline-block;
		vertical-align: top;
		width: 50%;
		&.full {
			width: 100%;
		}
	}
	.group {
		margin: 0 0 20px 0;
	}
	.field {
		position: relative;
		input[type="text"], input[type="tel"], input[type="password"], input[type="email"], textarea {
			padding: 0 30px;
			width: 100%;
			height: 50px;
			font-size: 14px;
			color: #000000;
			font-family: $base_font_family;
			font-weight: 400;
			border: 1px solid #fff;
			background: #fff;
			@include transition(all 0.3s ease 0s);
			@include border-radius(50px);
			@include box-shadow(0 10px 20px rgba(0,0,0,0.2));
			&.error {
				border-color: red;
			}
		}
		textarea {
			padding: 15px 30px;
			height: 135px;
			resize: none;
			@include border-radius(25px);
		}
		label.error {
			position: absolute;
			left: 0;
			bottom: -18px;
			font-size: 12px;
			color: red;
			text-align: left;
			color: red;
			width: 100%;
		}
	}
	.info-text {
		margin: 0;
		padding: 0 0 0 35px;
		position: relative;
		float: left;
		width: 222px;
		display: block;
		font-size: 14px;
		line-height: 21px;
		color: $base_white_color;
		font-weight: 400;
		a {
			color: #62d7ff;
			font-weight: 400;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		.jq-checkbox {
			position: absolute!important;
			left: 0;
			top: 1px;
		}
	}
	.submit-btn {
		margin-top: 0;
		float: right;
		display: block;
		width: 220px;
		cursor: pointer;
	}
}

/*Popups*/

.overlay, .cf-overlay {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	z-index: 101;
}

.nonebox {
	position: absolute;
	left: 50%;
	top: -3000px;
	width: 540px;
	margin: 0 0 0 -270px;
	padding: 40px 30px;
	background: url(../images/form_color.png);
	z-index: 1002;
	text-align: left;
	@include border-radius(20px);
	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 25px;
		height: 25px;
		background: url(../images/nclose.png) no-repeat;
		background-size: contain;
		font-size: 0;
		cursor: pointer;
		filter: brightness(0) invert(1);
	}
	.title {
		margin: 0 0 25px 0;
		font-size: 40px;
		line-height: 50px;
		color: $base_white_color;
		font-weight: 700;
	}
}
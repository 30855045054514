/* Inner */

.section.inner {
	padding: 30px 0;
	background: url(../images/patt.png);
}

.section.inner-white {
	background: #fff;
}

/* Inner Services */

.section.inner-services {
	padding-bottom: 0;
	.service-item {
		min-height: 410px;
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

/* Inner Team */

.section.inner-team {
	padding-bottom: 0;
}

.team-items {
	margin: 0 -10px;
	font-size: 0;
	.team-col {
		padding: 0 10px 40px 10px;
		display: inline-block;
		vertical-align: top;
		width: 33.333%;
	}
}

.team-item {
	position: relative;
	padding: 12px;
	min-height: 520px;
	background: #ecf6fa;
	@include border-radius(15px);
	@include transition(all 0.3s ease 0s);
	.image {
		margin-bottom: 15px;
		font-size: 0;
		img {
			width: 100%;
			height: auto;
			@include border-radius(10px);
			@include transition(all 0.3s ease 0s);
		}
	}
	.desc {
		padding: 0 20px;
		text-align: center;
		a {
			color: #414141;
		}
		.adr {
			position: relative;
			margin-bottom: 25px;
			padding-left: 22px;
			display: inline-block;
			vertical-align: top;
			font-size: 18px;
			line-height: 20px;
			font-weight: 300;
			color: #808080;
			&:before, &:after {
				margin-top: -10px;
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				width: 14px;
				height: 20px;
				background: url(../images/adr.png) no-repeat;
				background-size: contain;
			}
			&:after {
				background: url(../images/adr2.png) no-repeat;
				background-size: contain;
				opacity: 0;
			}
		}
		.name {
			margin: 0 0 10px 0;
			font-size: 24px;
			line-height: 30px;
			color: #414141;
			font-weight: 700;
			display: block;
		}
		.status {
			font-size: 18px;
			line-height: 20px;
			color: #414141;
			font-weight: 300;
		}
	}
	&:hover {
		background: #31758c;
		.image {
			img {
				@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
			}
		}
		.desc {
			.adr {
				&:before {
					opacity: 0;
				}
				&:after {
					opacity: 1;
				}
			}
			a, .adr, .name, .status {
				color: $base_white_color;
			}
			a, .name, .status {
				text-decoration: underline;
			}
		}
	}
}

/* Inner Pricing */

.section.inner-pricing {
	overflow: hidden;
	padding-bottom: 0;
}

.pricing-item {
	position: relative;
	margin: 0 0 20px 0;
	&.active {
		.pricing-title {
			@include box-shadow(0 10px 20px rgba(66,117,140,0.2));
			&:before {
				margin-left: -15px;
				width: 31px;
				height: 18px;
				@include transform(rotate(0deg));
			}
		}
	}
	.pricing-title {
		position: relative;
		padding: 25px 35px;
		font-weight: 700;
		color: #fff;
		font-size: 30px;
		line-height: 30px;
		background: #31758c;
		cursor: pointer;
		background: -moz-linear-gradient(left, #5096b1 0%, #31758c 100%);
		background: -webkit-linear-gradient(left, #5096b1 0%,#31758c 100%);
		background: linear-gradient(to right, #5096b1 0%,#31758c 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5096b1', endColorstr='#31758c',GradientType=1 );
		@include border-radius(8px);
		@include transition(all 0.3s ease 0s);
		&:before {
			margin-left: -9px;
			content: '';
			position: absolute;
			left: 50%;
			bottom: 10px;
			width: 18px;
			height: 10px;
			background: url(../images/pr_arr.png) no-repeat;
			background-size: contain;
			@include transition(all 0.3s ease 0s);
			@include transform(rotate(180deg));
		}
	}
	.pricing-content {
		padding: 0 30px;
		table {
			margin: 0;
			padding: 0;
			width: 100%;
			border-collapse: collapse;
			tr {
				&:nth-child(odd) {
					td {
						background: #ebf6fa;
					}
				}
			}
			td {
				padding: 20px 70px;
				background: #d5e4e9;
				font-size: 18px;
				line-height: 24px;
				color: #333333;
				&:last-child {
					width: 350px;
				}
				.prices {
					margin-right: 30px;
					display: inline-block;
					&.sale {
						.new-price {
							color: #31758c;
						}
					}
				}
				.old-price {
					font-size: 12px;
					line-height: 12px;
					color: $base_text_color;
					font-weight: 300;
					text-decoration: line-through;
				}
				.new-price {
					font-size: 18px;
					line-height: 24px;
					font-weight: 800;
					color: $base_text_color;
				}
				.label {
					padding: 0 7px;
					position: relative;
					top: -2px;
					display: inline-block;
					height: 27px;
					line-height: 27px;
					font-size: 12px;
					font-weight: 800;
					color: #fff;
					background: #71c3d7;
					@include border-radius(8px 0 8px 0);
					@include box-shadow(0 10px 20px rgba(66,117,140,0.2));
				}
			}
		}
	}
}

/* Inner Services 2 */

.services-category {
	.list {
		float: left;
		width: 480px;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin: 0 0 10px 0;
				a {
					position: relative;
					padding: 20px 25px;
					display: block;
					font-size: 18px;
					line-height: 20px;
					color: #31758c;
					font-weight: 500;
					text-decoration: none;
					background: #ebf6fa;
					@include border-radius(5px);
					@include transition(all 0.3s ease 0s);
					&:before {
						margin-top: -7px;
						content: '';
						position: absolute;
						left: -30px;
						top: 50%;
						width: 19px;
						height: 14px;
						background: url(../images/point.png) no-repeat;
						opacity: 0;
						@include transition(all 0.3s ease 0s);
						@include box-shadow(0 10px 10px rgba(0,15,126,0.1));
					}
					&:hover {
						margin: 0 -10px;
						color: #fff;
						background: #31758c;
						background: -moz-linear-gradient(left, #31758c 0%, #5096b1 100%);
						background: -webkit-linear-gradient(left, #31758c 0%,#5096b1 100%);
						background: linear-gradient(to right, #31758c 0%,#5096b1 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31758c', endColorstr='#5096b1',GradientType=1 );
						@include box-shadow(0 10px 10px rgba(0,15,126,0.1));
						&:before {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	.image {
		float: right;
		width: 580px;
		img {
			width: 100%;
			height: auto;
			@include border-radius(18px);
		}
	}
}

.section.inner-services-info {
	margin-top: 30px;
	padding: 70px 0 15px 0;
	background: #ebf6fa;
	z-index: 2;
	.services-info {
		position: relative;
		max-width: 570px;
		&:before {
			content: '';
			position: absolute;
			left: -90px;
			top: -30px;
			right: -90px;
			bottom: -60px;
			border: 2px solid #31758c;
			pointer-events: none;
			@include border-radius(30px);
		}
		.image {
			position: absolute;
			bottom: -95px;
			right: -791px;
			width: 791px;
			height: 728px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
}

/* Inner Contacts */

.contacts-box {

}

.contacts-info-items {
	float: left;
	width: 380px;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.contacts-info-item {
	margin: 0 0 30px 0;
	padding: 30px;
	min-height: 250px;
	background: #ecf6fa;
	cursor: pointer;
	@include border-radius(18px);
	@include transition(all 0.3s ease 0s);
	&.active {
		margin-right: -20px;
		background: #31758c;
		@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
		.name {
			color: $base_white_color;
		}
		.info {
			ul {
				li {
					color: $base_white_color;
					&:before {
						filter: brightness(0) invert(1);
					}
					a {
						color: $base_white_color;
					}
				}
			}
		}
	}
	.name {
		margin: 0 0 25px 0;
		font-size: 24px;
		line-height: 24px;
		color: $base_text_color;
		font-weight: 500;
	}
	.info {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				position: relative;
				margin: 0 0 7px 0;
				padding: 0 0 0 40px;
				font-size: 18px;
				line-height: 24px;
				color: $base_text_color;
				font-weight: 400;
				&.adr {
					&:before {
						background: url(../images/c_adr.png) no-repeat;
						background-size: contain;
					}
				}
				&.tel {
					&:before {
						background: url(../images/c_tel.png) no-repeat;
						background-size: contain;
					}
				}
				&.mail {
					&:before {
						background: url(../images/c_mail.png) no-repeat;
						background-size: contain;
					}
				}
				&.time {
					&:before {
						background: url(../images/c_time.png) no-repeat;
						background-size: contain;
					}
				}
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 3px;
					width: 20px;
					height: 20px;
				}
				a {
					color: $base_text_color;
				}
			}
		}
	}
}

.contacts-map-items {
	position: relative;
	float: right;
	width: 680px;
	background: #5390a6;
	@include border-radius(18px);
	.c-map {
		position: relative;
		overflow: hidden;
		height: 680px;
		@include border-radius(18px);
		@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
	}
	.c-form {
		padding: 25px 100px;
	}
	.title {
		margin: 0 0 20px 0;
		font-size: 44px;
		line-height: 50px;
		color: $base_white_color;
		font-weight: 700;
		span {
			display: block;
			font-size: 24px;
			line-height: 24px;
			color: #cfdfe5;
		}
	}
}

/* Inner Team Page */

.team-page {
	.team-image {
		position: relative;
		float: left;
		width: 502px;
		font-size: 0;
		&:after {
			content: '';
			position: absolute;
			left: 20px;
			top: 20px;
			width: 60px;
			height: 60px;
			background: url(../images/t_logo.png) no-repeat;
			background-size: contain;
		}
		img {
			width: 100%;
			height: auto;
			@include border-radius(18px);
		}
	}
	.team-desc {
		float: right;
		width: 580px;
		.title {
			margin: 0 0 15px 0;
			font-size: 18px;
			color: #3e3e3e;
			font-weight: 700;
		}
	}
	.info {
		.info-group {
			margin-bottom: 25px;
			&:after {
				content: '';
				position: relative;
				clear: both;
				display: block;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		.label {
			float: left;
			span {
				margin: 0 0 5px 0;
				display: block;
				font-size: 18px;
				line-height: 20px;
				color: #3c819a;
			}
			.tel, .adr {
				position: relative;
				padding: 0 0 0 30px;
				font-size: 18px;
				line-height: 20px;
				font-weight: 300;
				color: #808080;
				text-decoration: none;
				&:before {
					margin-top: -10px;
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					width: 20px;
					height: 20px;
					background-position: center center;
					background-repeat: no-repeat;
				}
			}
			.tel {
				&:before {
					background-image: url(../images/t_tel.png);
				}
			}
			.adr {
				&:before {
					background-image: url(../images/t_adr.png);
				}
			}
		}
		.btn {
			padding: 0 20px;
			margin-right: 95px;
			float: right;
			width: 185px;
			font-size: 12px;
		}
	}
	.info, .list, .education {
		margin: 0 0 55px 0;
	}
}

.section.inner-reviews {
	padding: 50px 0 120px 0;
	background: #ebf6fa;
}

.team-reviews {
	.titles {
		position: relative;
		margin: 0 0 75px 0;
		padding: 0 325px 0 0;
		&:before {
			content: '';
			position: absolute;
			top: 20px;
			right: -20px;
			width: 307px;
			height: 302px;
			background: url(../images/t_revs.png) no-repeat;
			background-size: contain;
		}
		.m-title {
			margin: 0 0 35px 0;
		}
	}
}

.reviews-item {
	margin: 0 0 30px 0;
	padding: 25px 100px 45px 100px;
	border: 2px solid #34a2cc;
	@include border-radius(30px);
	&:last-child {
		margin-bottom: 0;
	}
	.image {
		float: left;
		width: 80px;
		img {
			width: 100%;
			height: auto;
			@include border-radius(100%);
		}
	}
	.desc {
		margin-left: 100px;
	}
	.name, .date {
		margin-right: 50px;
		display: inline-block;
		vertical-align: top;
		font-size: 18px;
		line-height: 20px;
		color: #404040;
		font-weight: 200;
	}
	.date {
		margin-right: 0;
	}
	.text {
		margin-top: 20px;
		font-size: 18px;
		line-height: 24px;
		color: #404040;
		font-style: italic;
	}
}

/* Inner Services Page */

.services-intro {
	.desc {
		float: left;
		width: 580px;
	}
	.image {
		float: right;
		width: 480px;
		img {
			width: 100%;
			height: auto;
			@include border-radius(18px);
		}
	}
	.text-box p {
		font-size: 16px;
		line-height: 24px;
	}
}

.section.inner-stage {
	padding: 30px 0 20px 0;
	background: #ebf6fa;
}

.stage-items {
	margin: 0 -22px;
	padding: 0;
	font-size: 0;
	.stage-col {
		padding: 0 22px;
		display: inline-block;
		vertical-align: top;
		width: 50%;
	}
}

.stage-item, .types-item, .causes-item {
	margin-bottom: 22px;
	.image {
		float: left;
		width: 147px;
		img {
			width: 100%;
			height: auto;
			@include border-radius(147px);
			@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
		}
	}
	.desc {
		margin-left: 183px;
	}
	.name {
		margin: 0 0 8px 0;
		font-size: 16px;
		line-height: 20px;
		color: #31758c;
		letter-spacing: 0.075em;
		text-transform: uppercase;
		font-weight: 700;
	}
	.text {
		font-size: 16px;
		line-height: 24px;
		color: #201b1b;
	}
}

.section.inner-types {
	padding: 40px 0 30px 0;
}

.types-info {
	.m-title {
		margin-bottom: 20px;
	}
	.desc {
		width: 570px;
	}
	.image {
		position: absolute;
		right: -100px;
		bottom: -52px;
		width: 639px;
		height: 464px;
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.section.inner-causes {
	padding: 30px 0 60px 0;
	background: #ebf6fa;
	.m-title {
		margin-bottom: 30px;
	}
}

.causes-info {
	.image {
		margin-top: -8px;
		float: left;
		width: 678px;
		font-size: 0;
		@include box-shadow(0 15px 20px rgba(0,81,101,0.15));
		@include border-radius(20px);
		img {
			width: 100%;
		}
	}
	.desc {
		padding: 35px 55px 15px 55px;
		float: right;
		width: 478px;
		min-height: 499px;
		border: 2px solid #31758c;
		@include border-radius(20px);
	}
}

.section.inner-pricing-s {
	padding: 30px 0 90px 0;
	.m-title {
		margin-bottom: 20px;
	}
}

.pricing-table {
	margin: 0 -20px;
	table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 20px 10px;
		td {
			padding: 15px 25px;
			background: #ebf6fa;
			width: 50%;
			font-size: 18px;
			line-height: 24px;
			color: #333333;
			@include border-radius(6px);
			&:last-child {
				text-align: center;
			}
			.name {
				position: relative;
				z-index: 2;
			}
			.price {
				position: relative;
				display: block;
				font-size: 18px;
				color: #333;
				font-weight: 800;
				&:before {
					content: '';
					position: absolute;
					left: -5000px;
					top: 50%;
					width: 15000px;
					height: 2px;
					background: #ebf6fa;
				}
				span {
					position: relative;
					z-index: 2;
				}
			}
		}
	}
}

.section.inner-solution {
	padding: 30px 0 90px 0;
	background: #ebf6fa;
	.m-title {
		margin-bottom: 35px;
	}
}

.solution-carousel {
	position: relative;
	.owl-carousel .owl-stage-outer {
		padding-bottom: 0;
	}
	.owl-prev, .owl-next {
		position: absolute;
		left: 0;
		top: 103px;
		width: 18px;
		height: 31px;
		background: url(../images/s_nav.png) no-repeat;
		background-size: contain;
		font-size: 0;
		cursor: pointer;
		opacity: 0.3;
		@include transition(all 0.3s ease 0s);
		&:hover {
			opacity: 1;
		}
	}
	.owl-next {
		left: auto;
		right: 0;
		@include transform(scaleX(-1));
	}
	.owl-dots {
		position: absolute;
		left: 0;
		top: 114px;
		width: 100%;
		height: 1px;
		text-align: center;
		.owl-dot {
			margin: 0 4px;
			display: inline-block;
			vertical-align: top;
			width: 9px;
			height: 9px;
			cursor: pointer;
			background: #b3cfd9;
			@include border-radius(9px);
			&.active {
				background: #31758c;
			}
		}
	}
}

.solution-items {
	margin: 0 -50px;
	font-size: 0;
	.solution-col {
		padding: 0 50px;
		display: inline-block;
		vertical-align: top;
		width: 50%;
	}
}

.solution-item {
	&.before {
		.image {
			&:after {
				background-color: #b52f31;
				background-image: url(../images/sol_ic1.png);
			}
		}
		.desc {
			.name {
				color: #bf3436;
			}
		}
	}
	&.after {
		.image {
			&:after {
				background-color: #4b91ab;
				background-image: url(../images/sol_ic2.png);
			}
		}
		.desc {
			.name {
				color: #31758c;
			}
		}
	}
	.image {
		position: relative;
		margin: 0 auto 45px auto;
		width: 380px;
		&:after {
			content: '';
			position: absolute;
			right: -55px;
			bottom: -28px;
			width: 100px;
			height: 100px;
			background-repeat: no-repeat;
			background-position: center center;
			@include border-radius(20px);
			@include box-shadow(0 10px 20px rgba(0,15,126,0.35));
		}
		img {
			width: 100%;
			height: auto;
			@include border-radius(20px);
		}
	}
	.name {
		margin: 0 0 10px 0;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.025em;
		text-transform: uppercase;
		font-weight: 400;
	}
	.text {
		font-size: 16px;
		line-height: 24px;
		color: #333333;
	}
}

/* Inner About Page */

.section.inner-interview {
	padding: 30px 0 70px 0;
	background: #fff;
	z-index: 2;
	@include box-shadow(0 4px 6px rgba(0,0,0,0.2));
	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 9px;
		background: url(../images/line.png) repeat-x;
	}
}

.interview-intro {
	margin-bottom: 37px;
	padding: 37px;
	background: #ebfaff;
	@include border-radius(25px);
	.title {
		margin-right: 539px;
		padding: 80px 80px 0 80px;
		font-size: 28px;
		line-height: 36px;
		font-weight: 300;
		text-align: center;
		color: #1a1a1a;
		strong {
			display: block;
			font-weight: 700;
			color: #5096b1;
		}
	}
	.image {
		float: right;
		width: 539px;
		img {
			width: 100%;
			height: auto;
			@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
			@include border-radius(8px);
		}
	}
}

.interview-chat {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			margin-bottom: 35px;
			text-align: left;
			.chat-item {
				padding: 40px;
				position: relative;
				display: inline-block;
				vertical-align: top;
				background: #31758c;
				background: -moz-linear-gradient(left, #31758c 0%, #5096b1 100%);
				background: -webkit-linear-gradient(left, #31758c 0%,#5096b1 100%);
				background: linear-gradient(to right, #31758c 0%,#5096b1 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31758c', endColorstr='#5096b1',GradientType=1 );
				font-size: 18px;
				line-height: 30px;
				color: #fff;
				font-weight: 700;
				@include box-shadow(0 5px 0 rgba(0,0,0,0.25));
				@include border-radius(30px);
				.icon {
					position: absolute;
					left: -90px;
					top: 0;
					width: 50px;
					height: 50px;
					background: #31758c;
					background: -moz-linear-gradient(left, #31758c 0%, #5096b1 100%);
					background: -webkit-linear-gradient(left, #31758c 0%,#5096b1 100%);
					background: linear-gradient(to right, #31758c 0%,#5096b1 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31758c', endColorstr='#5096b1',GradientType=1 );
					@include border-radius(50px);
					@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: url(../images/int_ic.png) no-repeat center center;
					}
				}
			}
			&.answer {
				text-align: right;
				.chat-item {
					padding: 40px 70px;
					margin-left: 110px;
					background: #ebfaff;
					color: #000000;
					font-weight: 300;
					text-align: left;
					.icon {
						left: auto;
						right: -90px;
						top: 0;
						&:before {
							display: none;
						}
						img {
							width: 100%;
							height: 100%;
							@include border-radius(100%);
						}
					}
				}
			}
		}
	}
	.view-more {
		margin-left: -50px;
		position: absolute;
		left: 50%;
		bottom: -70px;
		width: 100px;
		padding: 30px 0 0 0;
		font-size: 12px;
		color: #4f95b0;
		font-weight: 300;
		text-align: center;
		&:before {
			margin-left: -15px;
			content: '';
			position: absolute;
			left: 50%;
			top: 0;
			width: 31px;
			height: 18px;
			background: url(../images/arr_down.png) no-repeat;
			background-size: contain;
		}
		&.active {
			font-size: 0;
			&:before {
				@include transform(rotate(180deg));
			}
		}
	}
}

.section.inner-numbers {
	padding: 70px 0;
	background: #d6ecf3;
	background: -moz-radial-gradient(center, ellipse cover, #d6ecf3 0%, #ebfaff 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #d6ecf3 0%,#ebfaff 100%);
	background: radial-gradient(ellipse at center, #d6ecf3 0%,#ebfaff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d6ecf3', endColorstr='#ebfaff',GradientType=1 );
	.m-title {
		position: relative;
		color: #4f96b0;
		&:before {
			margin-top: 4px;
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 260px;
			height: 3px;
			background: #fff;
		}
	}
}

.numbers-items {
	margin: 0 -10px;
	font-size: 0;
	.numbers-col {
		padding: 0 10px 20px 10px;
		display: inline-block;
		vertical-align: top;
		width: 25%;
		&.large {
			width: 50%;
		}
		&.small {
			width: 17%;
		}
		&.medium {
			width: 33%;
		}
	}
}

.numbers-item {
	padding: 50px 35px 0 35px;
	border: 2px solid #fff;
	font-size: 18px;
	line-height: 36px;
	color: #4f96b0;
	font-weight: 700;
	min-height: 260px;
	@include border-radius(30px);
	strong {
		padding: 0;
		display: block;
		font-size: 72px;
		line-height: 72px;
		font-weight: 800;
	}
}

.section.inner-videos {
	padding: 150px 0 100px 0;
	background: url(../images/patt.png);
}

.videos-box {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: -65px;
		bottom: -55px;
		right: 315px;
		left: -1000px;
		border: 2px solid $base_link_color;
		@include border-radius(30px);
	}
	.video-img {
		position: relative;
		display: block;
		height: 590px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		@include border-radius(20px);
		@include box-shadow(0 10px 49px rgba(28,38,41,0.46));
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.55);
			@include border-radius(20px);
		}
	}
	.video-desc {
		margin: -90px 0 0 -250px;
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 60px 30px;
		width: 500px;
		background: rgba(0,0,0,0.55);
		font-size: 18px;
		line-height: 30px;
		color: #fff;
		font-weight: 700;
		text-decoration: underline;
		@include border-radius(6px);
		&:before {
			margin-top: -57px;
			content: '';
			position: absolute;
			top: 50%;
			right: 70px;
			width: 115px;
			height: 115px;
			background: url(../images/play.png) no-repeat;
			background-size: contain;
		}
	}
	a {
		&:hover {
			.video-desc {
				text-decoration: none;
			}
		}
	}
}

.section.inner-sertificate {
	padding: 70px 0;
	background: #d6ecf3;
	background: -moz-radial-gradient(center, ellipse cover, #d6ecf3 0%, #ebfaff 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #d6ecf3 0%,#ebfaff 100%);
	background: radial-gradient(ellipse at center, #d6ecf3 0%,#ebfaff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d6ecf3', endColorstr='#ebfaff',GradientType=1 );
	.m-title {
		margin-bottom: 70px;
		padding-bottom: 20px;
		color: #4f96b0;
		border-bottom: 2px solid #bad8e2;
	}
}

.sert-carousel {
	position: relative;
	&:before, &:after {
		margin-top: -44px;
		margin-left: -4px;
		content: '';
		position: absolute;
		left: 33.333%;
		top: 50%;
		width: 9px;
		height: 9px;
		background: #5390a6;
		opacity: 0.3;
		@include border-radius(9px);
	}
	&:after {
		left: 66.666%;
	}
	.item {
		text-align: center;
	}
	.owl-carousel .owl-stage-outer {
		padding-bottom: 80px;
		border-bottom: 2px solid #bad8e2;
	}
	.owl-prev, .owl-next {
		margin-top: -45px;
		position: absolute;
		left: -30px;
		top: 50%;
		width: 18px;
		height: 31px;
		background: url(../images/s_nav.png) no-repeat;
		background-size: contain;
		font-size: 0;
		cursor: pointer;
		opacity: 0.3;
		@include transition(all 0.3s ease 0s);
		&:hover {
			opacity: 1;
		}
	}
	.owl-next {
		left: auto;
		right: -30px;
		@include transform(scaleX(-1));
	}
}

.sert-item {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 306px;
	text-align: center;
	background: #5390a6;
	border: 10px solid #5390a6;
	font-size: 0;
	@include border-radius(10px);
	@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
	img {
		max-width: 100%;
	}
}

.section.inner-history {
	padding: 80px 0;
	background: url(../images/history_bg.jpg) repeat-x top center;
	.m-title {
		margin-bottom: 80px;
		position: relative;
		color: #4f96b0;
		&:before {
			margin-top: 4px;
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 840px;
			height: 3px;
			background: #b3d4df;
		}
	}
}

.history-box {
	margin-left: -20px;
	position: relative;
	overflow: auto;
	width: 1230px;
}

.history-items {
	padding-left: 20px;
	margin-bottom: 150px;
	width: 1210px;
	height: 282px;
	background: url(../images/his_bg.png) no-repeat;
	background-size: contain;
	font-size: 0;
	.history-col {
		padding-right: 30px;
		display: inline-block;
		vertical-align: top;
		width: 25%;
	}
}

.history-item {
	margin-top: -5px;
	position: relative;
	font-size: 18px;
	line-height: 30px;
	color: #414141;
	height: 282px;
	font-weight: 700;
	.date {
		position: absolute;
		left: 0;
		bottom: -20px;
		font-size: 24px;
		font-weight: 700;
		color: #4f96b0;
	}
}

.new-years {
	position: relative;
	padding: 35px 45px 35px 300px;
	display: inline-block;
	vertical-align: top;
	background: #edf4f7;
	font-size: 18px;
	line-height: 30px;
	color: #404040;
	font-weight: 500;
	&:before {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 1000%;
		height: 100%;
		background: #edf4f7;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: -120px;
		border: 80px solid transparent;
		border-left: 40px solid #edf4f7;
	}
	.text {
		position: relative;
		z-index: 2;
	}
	.date {
		margin-top: -24px;
		padding: 0 0 0 100px;
		position: absolute;
		left: 0;
		top: 50%;
		font-size: 48px;
		line-height: 48px;
		color: #404040;
		font-weight: 800;
		&:before {
			margin-top: -2px;
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			width: 88px;
			height: 5px;
			background: #4f96b0;
		}
	}
}
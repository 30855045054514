.jq-checkbox,
.jq-radio {
	position: relative;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.jq-checkbox input,
.jq-radio input {
	position: absolute;
	z-index: -1;
	margin: 0;
	padding: 0;
	opacity: 0;
}
.jq-file {
	position: relative;
	display: inline-block;
	overflow: hidden;
}
.jq-file input {
	position: absolute;
	top: 0;
	right: 0;
	height: auto;
	margin: 0;
	padding: 0;
	opacity: 0;
	font-size: 100px;
	line-height: 1em;
}
.jq-file__name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.jq-selectbox,
.jq-select-multiple {
	position: relative;
	display: inline-block;
}
.jq-selectbox select,
.jq-select-multiple select {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	opacity: 0;
}
.jq-selectbox li,
.jq-select-multiple li {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
}
.jq-selectbox {
	z-index: 10!important;
}
.jq-selectbox__select {
	position: relative;
}
.jq-selectbox__select-text {
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.jq-selectbox__dropdown {
	position: absolute;
}
.jq-selectbox__search input {
	-webkit-appearance: textfield;
}
.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
	-webkit-appearance: none;
}
.jq-selectbox__dropdown ul {
	position: relative;
	overflow: auto;
	overflow-x: hidden;
	list-style: none;
	-webkit-overflow-scrolling: touch;
}
.jq-select-multiple ul {
	position: relative;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}
.jq-number {
	display: inline-block;
}
.jq-number__field input {
	-moz-appearance: textfield;
	text-align: left; /* для Opera Presto */
}
.jq-number__field input::-webkit-inner-spin-button,
.jq-number__field input::-webkit-outer-spin-button {
	margin: 0; /* в каких-то браузерах присутствует отступ */
	-webkit-appearance: none;
}


.jq-checkbox,
.jq-radio {
	width: 20px;
	height: 20px;
	margin: 0 4px 0 0;
	background: url(../images/check.png) no-repeat;
	background-size: contain;
	cursor: pointer;
}
.jq-checkbox.disabled,
.jq-radio.disabled {
	opacity: .55;
}
.jq-checkbox.checked .jq-checkbox__div {
	width: 20px;
	height: 20px;
	background: url(../images/check2.png) no-repeat;
	background-size: contain;
}
.jq-radio.checked .jq-radio__div {
	width: 20px;
	height: 20px;
	background: url(../images/check2.png) no-repeat;
	background-size: contain;
}
.jq-file {
	width: 270px;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
}
.jq-file input {
	cursor: pointer;
}
.jq-file__name {
	box-sizing: border-box;
	width: 100%;
	height: 34px;
	padding: 0 80px 0 10px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1;
	font: 14px/32px Arial, sans-serif;
	color: #333;
}
.jq-file__browse {
	position: absolute;
	top: 1px;
	right: 1px;
	padding: 0 10px;
	border-left: 1px solid #CCC;
	border-radius: 0 4px 4px 0;
	background: linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/32px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
}
.jq-file:hover .jq-file__browse {
	background: linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-file:active .jq-file__browse {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-file.focused .jq-file__name {
	border: 1px solid #5794BF;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-number {
	position: relative;
	vertical-align: middle;
	padding: 0;
	text-align: center;
}
.jq-number__field {
	width: 140px;
}
.jq-number__field input {
	padding: 0 45px;
	width: 100%;
	height: 46px;
	border: none;
	outline: none;
	font-family: $base_font_family;
	font-size: 14px;
	color: $base_text_color;
	background: $base_white_color;
	border: 1px solid #B3B3B3;
	text-align: center;
	@include border-radius(6px);
}
.jq-number__spin {
	position: absolute;
	top: 0;
	left: 0;
	width: 45px;
	height: 46px;
	border: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	border-right: 1px solid #B3B3B3;
}
.jq-number__spin.plus {
	left: auto;
	right: 0;
	border-right: none;
	border-left: 1px solid #B3B3B3;
}
.jq-number__spin:hover {
	
}
.jq-number__spin:active {
	
}
.jq-number__spin.minus:after, .jq-number__spin.plus:after {
	content: '-';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	line-height: 46px;
}
.jq-number__spin.plus:after {
	content: '+';
}
.jq-number.focused .jq-number__field {
	border: 1px solid #5794BF;
}
.jq-number.disabled .jq-number__field,
.jq-number.disabled .jq-number__spin {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-number.disabled .jq-number__spin:after {
	border-bottom-color: #AAA;
}
.jq-number.disabled .jq-number__spin.minus:after {
	border-top-color: #AAA;
}
.jq-selectbox {
	width: 100%;
	display: block;
	vertical-align: middle;
	cursor: pointer;
}
.jq-selectbox__select {
	height: 60px;
	padding: 0 40px 0 15px;
	border: none;
	background: #F5F5F5;
	font-family: $base_font_family;
	font-size: 14px;
	line-height: 56px;
	color: $base_text_color;
	border: 2px solid #F5F5F5;
	@include border-radius(2px);
}
.jq-selectbox__select:hover {
	
}
.jq-selectbox__select:active {
	
}
.jq-selectbox.focused .jq-selectbox__select {
	
}
.jq-selectbox.disabled .jq-selectbox__select {
	opacity: 0.5;
}
.jq-selectbox__select-text {
	display: block;
	width: 100%;
}
.jq-selectbox .placeholder {
	color: #888;
}
.jq-selectbox__trigger {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
}
.jq-selectbox__trigger-arrow {
	margin-top: -7px;
	position: absolute;
	top: 50%;
	right: 15px;
	width: 13px;
	height: 15px;
	background: url(../images/arr.svg) no-repeat;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow {
	
}
.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
	
}
.jq-selectbox__dropdown {
	box-sizing: border-box;
	width: 100%;
	padding: 0;
	border-top: 1px solid #B3B3B3;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	background: #F5F5F5;
	font-family: $base_font_family;
	font-size: 14px;
	line-height: 20px;
	color: $base_text_color;
	@include box-shadow(0 20px 40px rgba(0,0,0,0.1));
}
.jq-selectbox__search {
	margin: 5px;
}
.jq-selectbox__search input {
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	border: 1px solid #CCC;
	border-radius: 3px;
	outline: none;
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==') no-repeat 100% 50%;
	box-shadow: inset 1px 1px #F1F1F1;
	color: #333;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 0;
}
.jq-selectbox li {
	padding: 15px;
	color: $base_text_color;
}
.jq-selectbox li.selected {
	background-color: $base_link_color;
	color: #FFF;
}
.jq-selectbox li:hover {
	background-color: $base_link_color;
	color: #FFF;
}
.jq-selectbox li.disabled {
	color: #AAA;
}
.jq-selectbox li.disabled:hover {
	
}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}
.jq-select-multiple {
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/18px Arial, sans-serif;
	color: #333;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
input[type='email'].styler,
input[type='password'].styler,
input[type='search'].styler,
input[type='tel'].styler,
input[type='text'].styler,
input[type='url'].styler,
textarea.styler {
	padding: 8px 9px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px Arial, sans-serif;
	color: #333;
}
input[type='search'].styler {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
textarea.styler {
	overflow: auto;
}
input[type='email'].styler:hover,
input[type='password'].styler:hover,
input[type='search'].styler:hover,
input[type='tel'].styler:hover,
input[type='text'].styler:hover,
input[type='url'].styler:hover,
textarea.styler:hover {
	border-color: #B3B3B3;
}
input[type='email'].styler:hover:focus,
input[type='password'].styler:hover:focus,
input[type='search'].styler:hover:focus,
input[type='tel'].styler:hover:focus,
input[type='text'].styler:hover:focus,
input[type='url'].styler:hover:focus,
textarea.styler:hover:focus {
	border-color: #CCC;
	border-top-color: #B3B3B3;
	outline: none;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
button.styler,
input[type='button'].styler,
input[type='submit'].styler,
input[type='reset'].styler {
	overflow: visible;
	padding: 8px 11px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	outline: none;
	background: linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
	cursor: pointer;
}
button.styler.styler::-moz-focus-inner,
input[type='button'].styler.styler::-moz-focus-inner,
input[type='submit'].styler.styler::-moz-focus-inner,
input[type='reset'].styler.styler::-moz-focus-inner {
	padding: 0;
	border: 0;
}
button.styler:not([disabled]):hover,
input[type='button'].styler:not([disabled]):hover,
input[type='submit'].styler:not([disabled]):hover,
input[type='reset'].styler:not([disabled]):hover,
input[type='reset'].styler:hover {
	background: linear-gradient(#F6F6F6, #E6E6E6);
}
button.styler:active,
input[type='button'].styler:active,
input[type='submit'].styler:active,
input[type='reset'].styler:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
button.styler[disabled],
input[type='button'].styler[disabled],
input[type='submit'].styler[disabled] {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
/*Header*/

.header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 90;
	&.opened {
		overflow: auto;
		height: 100%;
		.popup-menu {
			left: 0;
		}
	}
	.logo {
		float: left;
		width: 293px;
		font-size: 0;
		a {
			text-decoration: none;
		}
		img {
			max-width: 100%;
		}
	}
	.head-top {
		position: relative;
		padding: 30px 0 0 0;
		height: 130px;
		background: #fff;
		z-index: 10;
	}
	.head-right {
		padding-top: 20px;
		float: right;
		width: 725px;
		font-size: 0;
	}
	.contacts {
		margin-left: 30px;
		float: right;
		.adr {
			margin: 0 0 5px 0;
			display: block;
			font-size: 12px;
			line-height: 12px;
			color: #808080;
		}
		.tel {
			display: block;
			font-size: 18px;
			line-height: 20px;
			color: $base_text_color;
			font-weight: 700;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.login-btn {
		padding-left: 35px;
		position: absolute;
		top: -20px;
		right: 0;
		font-size: 14px;
		line-height: 14px;
		color: #153e5c;
		text-decoration: none;
		&:before {
			margin-top: -6px;
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 22px;
			height: 13px;
			background: url(../images/login.png) no-repeat;
			background-size: contain;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	.tel-btn {
		position: absolute;
		top: 0;
		right: 45px;
		width: 32px;
		height: 32px;
		text-decoration: none;
		display: none;
		background: #4f96b0;
		@include border-radius(32px);
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url(../images/m_tel.svg) no-repeat;
			background-size: contain;
		}
	}
	.menu-btn {
		position: relative;
		padding: 0;
		display: none;
		width: 40px;
		height: 40px;
		background: #fff;
		@include border-radius(40px);
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url(../images/menu.svg) no-repeat center center;
			background-size: contain;
		}
	}
	.main-menu {
		padding: 10px 0;
		background: #31758c;
		background: -moz-linear-gradient(left, #31758c 0%, #5096b1 100%);
		background: -webkit-linear-gradient(left, #31758c 0%,#5096b1 100%);
		background: linear-gradient(to right, #31758c 0%,#5096b1 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31758c', endColorstr='#5096b1',GradientType=1 );
		height: 60px;
		.menu {
			padding: 9px 0;
			float: left;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				font-size: 0;
				li {
					padding: 0 35px;
					position: relative;
					display: inline-block;
					vertical-align: top;
					&:before {
						margin-top: -5px;
						content: '';
						position: absolute;
						top: 50%;
						right: -5px;
						width: 10px;
						height: 10px;
						background: rgba(255,255,255,0.25);
						@include border-radius(10px);
					}
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
						&:before {
							display: none;
						}
					}
					a {
						position: relative;
						display: inline-block;
						vertical-align: top;
						font-size: 14px;
						line-height: 20px;
						font-weight: 600;
						color: #aed4e1;
						letter-spacing: 0.025em;
						text-decoration: none;
						border-bottom: 1px solid #aed4e1;
						@include transition(all 0.3s ease 0s);
						&:hover {
							border-color: #fff;
							color: #fff;
						}
					}
					&.active {
						a {
							border-color: #fff;
							color: #fff;
							font-weight: 800;
						}
					}
				}
			}
		}
		.call-btn {
			padding: 0 20px;
			float: right;
			font-size: 12px;
			color: #454545;
			font-weight: 800;
			height: 40px;
			line-height: 40px;
			text-transform: uppercase;
			text-decoration: none;
			background: #fff;
			@include border-radius(40px);
		}
	}
}

.popup-menu {
	position: absolute;
	left: -200px;
	top: 70px;
	width: 200px;
	padding: 20px;
	background: #31758c;
	background: -moz-linear-gradient(left, #31758c 0%, #5096b1 100%);
	background: -webkit-linear-gradient(left, #31758c 0%,#5096b1 100%);
	background: linear-gradient(to right, #31758c 0%,#5096b1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31758c', endColorstr='#5096b1',GradientType=1 );
	z-index: 10;
	@include border-radius(0 12px 12px 0);
	@include transition(all 0.3s ease 0s);
	.menu {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			font-size: 0;
			li {
				margin: 0 0 30px 0;
				padding: 0 0 0 20px;
				position: relative;
				display: block;
				&:before {
					margin-top: -5px;
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					width: 10px;
					height: 10px;
					background: rgba(255,255,255,0.25);
					@include border-radius(10px);
				}
				&:last-child {
					margin-bottom: 0;
				}
				a {
					position: relative;
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					line-height: 20px;
					font-weight: 700;
					color: #aed4e1;
					letter-spacing: 0.025em;
					text-decoration: none;
					border-bottom: 1px solid #aed4e1;
					@include transition(all 0.3s ease 0s);
					&:hover {
						border-color: #fff;
						color: #fff;
					}
				}
				&.active {
					a {
						border-color: #fff;
						color: #fff;
					}
				}
			}
		}
	}
}

.popup-menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.5;
	display: none;
}
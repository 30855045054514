/* Home */

.section {
	position: relative;
}

.customNav {
	position: absolute;
	left: 0;
	bottom: 40px;
	z-index: 3;
	.customPag {
		display: inline-block;
		vertical-align: middle;
		.owl-dot {
			position: relative;
			top: -2px;
			margin: 0 5px;
			display: inline-block;
			vertical-align: middle;
			width: 10px;
			height: 10px;
			background: #fff;
			opacity: 0.3;
			cursor: pointer;
			@include border-radius(10px);
			@include transition(all 0.3s ease 0s);
			&.active {
				opacity: 1;
			}
		}
	}
	.owl-prev, .owl-next {
		margin: 0 5px;
		display: inline-block;
		vertical-align: middle;
		width: 18px;
		height: 31px;
		background: url(../images/nav.png) no-repeat;
		background-size: contain;
		font-size: 0;
		cursor: pointer;
		opacity: 0.3;
		@include transition(all 0.3s ease 0s);
		&:hover {
			opacity: 1;
		}
	}
	.owl-next {
		@include transform(scaleX(-1));
	}
}

/* Section Started */

.section.m-started {
	position: relative;
	overflow: hidden;
	background: #F5F5F5;
}

.started-carousel {
	.owl-carousel .owl-stage-outer {
		padding-bottom: 0;
	}
	.item {
		position: relative;
	}
	.slide-img {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 430px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		.slide-bg {
			position: relative;
			margin: 0 auto;
			width: 1180px;
			height: 430px;
			&:before {
				content: '';
				position: absolute;
				left: -380px;
				top: 0;
				width: 1479px;
				height: 100%;
				background: url(../images/sh.png) repeat-y top left;
				background-size: contain;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: -380px;
				width: 875px;
				height: 100%;
				background: url(../images/slide_bg.png) no-repeat bottom right;
				background-size: auto 100%;
			}
		}
	}
	.slide-fw {
		padding: 55px 0 0 0;
		margin: 0 0 0 -590px;
		position: absolute;
		left: 50%;
		top: 0;
		width: 1180px;
		z-index: 2;
	}
	.desc {
		max-width: 494px;
	}
	.title {
		margin: 0 0 15px 0;
		font-size: 64px;
		line-height: 64px;
		font-weight: 700;
		color: $base_white_color;
		letter-spacing: -0.01em;
	}
	.subtitle {
		margin: 0 75px 35px 0;
		font-size: 18px;
		line-height: 24px;
		color: $base_white_color;
		font-weight: 400;
	}
	.btn {
		margin-right: 18px;
		width: 220px;
	}
}

/* Section Services */

.section.m-services {
	padding: 40px 0 20px 0;
	background: url(../images/patt.png);
}

.service-items {
	margin: 0 -10px;
	font-size: 0;
	.service-col {
		padding: 0 10px 30px 10px;
		display: inline-block;
		vertical-align: top;
		width: 33.333%;
	}
}

.service-item {
	position: relative;
	min-height: 240px;
	background: #ebf6fa;
	@include border-radius(18px);
	@include transition(all 0.3s ease 0s);
	&.item-1 {
		.name {
			&:before {
				background-image: url(../images/serv1.png);
			}
		}
	}
	&.item-2 {
		.name {
			&:before {
				background-image: url(../images/serv2.png);
			}
		}
	}
	&.item-3 {
		.name {
			&:before {
				background-image: url(../images/serv3.png);
			}
		}
	}
	&.item-4 {
		.name {
			&:before {
				background-image: url(../images/serv4.png);
			}
		}
	}
	&.item-5 {
		.name {
			&:before {
				background-image: url(../images/serv5.png);
			}
		}
	}
	&.item-6 {
		.name {
			&:before {
				background-image: url(../images/serv6.png);
			}
		}
	}
	&.item-7 {
		.name {
			&:before {
				background-image: url(../images/serv7.png);
			}
		}
	}
	&.item-8 {
		.name {
			&:before {
				background-image: url(../images/serv8.png);
			}
		}
	}
	&.item-9 {
		.name {
			&:before {
				background-image: url(../images/serv9.png);
			}
		}
	}
	a {
		color: #3f3f40;
		text-decoration: none;
	}
	.name {
		padding: 0 20px 0 100px;
		position: relative;
		display: flex;
		align-items: center;
		font-size: 18px;
		line-height: 18px;
		color: #3f3f40;
		font-weight: 600;
		height: 90px;
		border-bottom: 1px solid $base_white_color;
		@include transition(all 0.3s ease 0s);
		&:before {
			margin-top: -30px;
			content: '';
			position: absolute;
			left: 20px;
			top: 50%;
			width: 60px;
			height: 60px;
			background-color: #4eaccc;
			background-position: center center;
			background-repeat: no-repeat;
			@include border-radius(60px);
			@include box-shadow(0 10px 15px rgba(0,81,101,0.2));
		}
	}
	.desc {
		padding: 20px;
		display: block;
		.text {
			padding-bottom: 10px;
			display: block;
			min-height: 80px;
			font-size: 14px;
			line-height: 21px;
			color: $base_text_color;
			@include transition(all 0.3s ease 0s);
		}
		.links {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					margin: 0 0 16px 0;
					&:last-child {
						margin-bottom: 0;
					}
					a {
						font-size: 16px;
						color: $base_text_color;
						font-weight: 400;
					}
				}
			}
		}
	}
	&:hover {
		background: #31758c;
		a {
			color: $base_white_color;
		}
		.name {
			color: $base_white_color;
		}
		.desc {
			.text {
				color: $base_white_color;
			}
			.links {
				ul {
					li {
						a {
							color: $base_white_color;
						}
					}
				}
			}
		}
		.btn {
			color: $base_white_color;
			background: $base_hover_color;
			border-color: $base_hover_color;
			@include box-shadow(0 10px 20px rgba(0,0,0,0.2));
		}
	}
}

/* Section About */

.section.m-about {
	padding: 0 0 40px 0;
	background: #ebf6fa;
}

.benefits-box {
	padding: 40px 0 20px 0;
	margin-bottom: 80px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: -90px;
		right: -90px;
		top: 0;
		bottom: 0;
		border: 2px solid $base_hover_color;
		border-top: none;
		pointer-events: none;
		@include border-radius(0 0 30px 30px);
	}
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		right: -358px;
		width: 458px;
		height: 607px;
		background: url(../images/ben_bg.png) no-repeat;
		background-size: contain;
		pointer-events: none;
	}
}

.ben-items {
	font-size: 0;
	.ben-col {
		margin: 0 0 30px 0;
		padding: 0 100px 0 0;
		display: inline-block;
		vertical-align: top;
		width: 50%;
	}
}

.ben-item {
	padding: 0 0 0 138px;
	position: relative;
	font-size: 18px;
	line-height: 24px;
	color: $base_text_color;
	font-weight: 400;
	&.item-1 {
		&:before {
			background-image: url(../images/ben1.png);
		}
	}
	&.item-2 {
		&:before {
			background-image: url(../images/ben2.png);
		}
	}
	&.item-3 {
		&:before {
			background-image: url(../images/ben3.png);
		}
	}
	&.item-4 {
		&:before {
			background-image: url(../images/ben4.png);
		}
	}
	&.item-5 {
		&:before {
			background-image: url(../images/ben5.png);
		}
	}
	&.item-6 {
		&:before {
			background-image: url(../images/ben6.png);
		}
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: -25px;
		width: 80px;
		height: 80px;
		background-color: #4eaccc;
		background-position: center center;
		background-repeat: no-repeat;
		@include border-radius(80px);
		@include box-shadow(0 10px 15px rgba(0,81,101,0.20));
	}
	strong {
		margin: 0 0 15px 0;
		display: block;
		font-size: 24px;
		font-weight: 700;
	}
}

.about-box {
	padding: 30px 0 70px 0;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: -500px;
		right: 0;
		top: 0;
		bottom: 0;
		border: 2px solid $base_hover_color;
		border-left: none;
		pointer-events: none;
		@include border-radius(0 30px 30px 0);
	}
	&:after {
		content: '';
		position: absolute;
		bottom: -48px;
		left: -472px;
		width: 592px;
		height: 441px;
		background: url(../images/about_bg.png) no-repeat;
		background-size: contain;
		pointer-events: none;
	}
	.desc {
		position: relative;
		float: left;
		width: 485px;
		z-index: 2;
		.title {
			margin: 0 0 20px 0;
			font-size: 44px;
			line-height: 50px;
			font-weight: 700;
			color: #303234;
		}
		.text {
			margin: 0 0 40px 0;
			font-size: 18px;
			line-height: 24px;
			color: $base_text_color;
		}
		.btn {
			display: block;
			max-width: 220px;
		}
	}
	.image {
		margin-right: -90px;
		position: relative;
		float: right;
		width: 670px;
		z-index: 2;
		.owl-carousel {
			.owl-stage-outer {
				padding-bottom: 0;
			}
		}
		.slide {
			position: relative;
			display: block;
			width: 100%;
			height: 350px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			@include border-radius(12px);
		}
	}
	.customNav {
		bottom: -44px;
		width: 100%;
		text-align: center;
		.customPag {
			.owl-dot {
				background: #34a2cc;
			}
		}
		.owl-prev, .owl-next {
			background: url(../images/nav2.png) no-repeat;
		}
	}
}

/* Section Order */

.section.m-order {
	padding: 80px 0;
	background: url(../images/patt.png);
	&:before {
		margin-top: -1px;
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 2px;
		background: #31758c;
		background: -moz-linear-gradient(left, #31758c 0%, #5096b1 100%);
		background: -webkit-linear-gradient(left, #31758c 0%,#5096b1 100%);
		background: linear-gradient(to right, #31758c 0%,#5096b1 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31758c', endColorstr='#5096b1',GradientType=1 );
	}
}

.order-box {
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	max-width: 1080px;
	background: url(../images/form_color.png);
	@include border-radius(20px);
	.order-form {
		padding: 30px 0 30px 70px;
		position: relative;
		max-width: 530px;
		min-height: 430px;
		z-index: 2;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: -267px;
			width: 267px;
			height: 430px;
			background: url(../images/form_bg.png) no-repeat;
			background-size: 100% 100%;
		}
		.title {
			margin: 0 0 15px 0;
			font-size: 44px;
			line-height: 50px;
			color: $base_white_color;
			font-weight: 700;
		}
	}
	.image {
		position: absolute;
		top: 0;
		right: 0;
		width: 550px;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.circle-dots {
	position: absolute;
	left: 0;
	bottom: -55px;
	width: 100%;
	text-align: center;
	font-size: 0;
	.circle-dot {
		margin: 0 7px;
		display: inline-block;
		vertical-align: top;
		width: 21px;
		height: 21px;
		background: #032c80;
		opacity: 0.5;
		@include border-radius(21px);
	}
}

/* Section Contacts */

.section.m-contacts {
	padding: 40px 0 0 0;
	background: #5390a6;
	.tabs {
		position: relative;
	}
	.m-title {
		margin-bottom: 50px;
		color: #fff;
	}
	.circle-dots {
		bottom: 25px;
		.circle-dot {
			background: #779ead;
			opacity: 1;
		}
	}
}

.c-tab-menu {
	ul {
		margin: 0 -10px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
		li {
			position: relative;
			padding: 0 10px;
			width: 100%;
			a {
				position: relative;
				display: block;
				height: 40px;
				line-height: 40px;
				font-size: 14px;
				color: #fff;
				text-decoration: none;
				text-align: center;
				background: #153e5c;
				@include border-radius(10px 10px 0 0);
				&:before, &:after {
					content: '';
					position: absolute;
					left: -10px;
					top: 0;
					width: 100%;
					height: 100%;
					background: #153e5c;
					@include transform(skew(-20deg));
					@include border-radius(10px 10px 0 0);
				}
				&:after {
					right: -10px;
					left: auto;
					@include transform(skew(20deg));
				}
				span {
					position: relative;
					z-index: 2;
				}
			}
			&.active {
				z-index: 2;
				a {
					margin-top: -10px;
					background: url(../images/c_bg.png);
					height: 50px;
					line-height: 50px;
					font-size: 18px;
					font-weight: 600;
					&:before, &:after {
						background: url(../images/c_bg.png);
					}
				}
			}
		}
	}
}

.c-tab-items {
	position: relative;
	padding: 35px 0 100px 0;
	background: url(../images/c_bg.png);
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(../images/contacts_bg.png) no-repeat top center;
		background-size: cover;
	}
}

.c-item {
	.desc {
		padding: 35px 0 0 0;
		float: left;
		width: 434px;
		ul {
			margin: 0 0 50px 0;
			padding: 0;
			list-style: none;
			li {
				position: relative;
				margin: 0 0 5px 0;
				padding: 0 0 0 130px;
				font-size: 18px;
				line-height: 24px;
				color: #fff;
				span {
					position: absolute;
					left: 0;
					top: 0;
					text-align: right;
					width: 120px;
					font-size: 18px;
					line-height: 24px;
					font-weight: 300;
				}
				a {
					color: #fff;
				}
			}
		}
		.btn {
			margin-left: 94px;
		}
	}
	.map {
		position: relative;
		overflow: hidden;
		float: right;
		width: 676px;
		height: 300px;
		@include border-radius(15px);
	}
}

.c-navs {
	margin-left: -590px;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1180px;
	.c-prev, .c-next {
		margin-top: -30px;
		position: absolute;
		left: -60px;
		top: 0;
		width: 23px;
		height: 40px;
		background: url(../images/c_nav.png) no-repeat;
		background-size: contain;
		cursor: pointer;
	}
	.c-next {
		left: auto;
		right: -60px;
		@include transform(scaleX(-1));
	}
}
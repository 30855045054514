@media screen and (max-width: 1200px) {
	.fw {width: 720px;}

	/*header*/
	.header .head-right {padding-top: 10px; width: 400px;}
	.header .contacts {margin-left: 50px; margin-bottom: 15px;}
	.header .contacts .adr {margin: 0 0 2px 0;}
	.header .contacts .tel {font-size: 16px; line-height: 16px;}
	.header .main-menu .menu {display: none;}
	.header .menu-btn {float: left; display: block;}
	.popup-menu {top: 130px;}

	/*started*/
	.started-carousel .slide-fw {margin: 0 0 0 -360px; width: 720px;}

	/*services*/
	.service-items .service-col {width: 50%;}

	/*about*/
	.ben-items .ben-col {padding: 0 100px 0 0; width: 100%;}
	.about-box .desc {margin-bottom: 50px; float: none; width: 670px;}
	.about-box .image {margin-right: 0; float: none; width: 670px;}

	/*order*/
	.section.m-order {padding: 40px 0;}
	.order-box .order-form {padding: 30px 0 30px 30px; max-width: 430px;}
	.order-box .order-form .title {font-size: 40px;}
	.c-form .submit-btn {width: 168px;}
	.order-box .image {width: 280px;}

	/*contacts*/
	.c-tab-menu ul li a {font-size: 12px;}
	.c-tab-menu ul li.active a {font-size: 14px;}
	.c-item .desc {padding: 35px 0; float: none; width: 434px;}
	.c-item .map {float: none; width: 100%;}

	/*footer*/
	.footer {padding: 40px 0;}
	.footer .f-col {display: none;}
	.footer .f-col:last-child {display: block;}

	/*inner team*/
	.team-items .team-col {width: 50%;}

	/*inner pricing*/
	.pricing-item .pricing-title {padding: 20px 25px; font-size: 24px;}
	.pricing-item .pricing-content table td {padding: 20px 30px; font-size: 16px;}
	.pricing-item .pricing-content table td:last-child {width: auto;}
	.pricing-item .pricing-content table td .new-price {font-size: 16px;}

	/*inner services*/
	.section.inner-services-info {padding: 70px 0;}
	.services-category .list {width: 340px;}
	.services-category .image {width: 340px;}

	/*inner contacts*/
	.contacts-info-items {float: none; width: auto;}
	.contacts-info-items ul {margin: 0 0 0 -2%; padding: 0; font-size: 0;}
	.contacts-info-item {display: inline-block; vertical-align: top; width: 49%; margin-left: 1%;}
	.contacts-info-item.active {margin-right: 0;}
	.contacts-map-items {float: none; width: auto;}
	.contacts-map-items .c-map {height: 520px;}

	/*inner team page*/
	.team-page .team-image {margin: 0 0 30px 0; float: none;}
	.team-page .team-desc {float: none; width: auto;}
	.team-page .info .btn {margin-right: 225px;}
	.section.inner-reviews {padding: 50px 0;}
	.team-reviews .titles {padding: 0;}
	.reviews-item {padding: 25px 35px;}
	.m-subtitle {font-size: 20px; line-height: 40px;}

	/*inner services page*/
	.services-intro .desc {float: none; width: auto;}
	.services-intro .image {float: right; width: 340px;}
	.stage-items .stage-col {width: 100%;}
	.stage-item .image, .types-item .image, .causes-item .image {width: 120px;}
	.stage-item .desc, .types-item .desc, .causes-item .desc {margin-left: 150px;}
	.types-info .desc {width: auto;}
	.types-info .image {margin: 0 auto; position: relative; right: 0; bottom: -40px;}
	.section.inner-causes {padding-top: 40px;}
	.causes-info .image {margin-bottom: 32px; float: none; width: 100%;}
	.causes-info .desc {float: none; width: auto; min-height: auto;}
	.section.inner-pricing-s {padding: 40px 0 60px 0;}
	.pricing-table table td br {display: none;}
	.section.inner-solution {padding: 40px 0 60px 0;}
	.solution-items .solution-col {width: 100%;}
	.solution-items .solution-col:first-child {margin-bottom: 40px;}
	.solution-item .image {margin-bottom: 25px;}
	.solution-item .desc {margin: 0 auto; width: 380px; text-align: center;}
	.solution-carousel .owl-dots {display: none;}
	.solution-carousel .owl-prev, .solution-carousel .owl-next {margin-top: -15px; top: 50%;}

	/*inner about page*/
	.interview-intro {margin-bottom: 37px; padding: 30px;}
	.interview-intro .image {width: 340px;}
	.interview-intro .title {margin-right: 360px; padding: 20px 10px 0 10px; font-size: 24px; line-height: 30px;}
	.interview-chat ul li .chat-item {padding: 30px; margin-left: 70px;}
	.interview-chat ul li .chat-item .icon {left: -70px;}
	.interview-chat ul li.answer .chat-item {padding: 30px; margin-left: 0; margin-right: 70px;}
	.interview-chat ul li.answer .chat-item .icon {left: auto; right: -70px;}
	.section.inner-history .m-title {margin-bottom: 50px;}
	.section.inner-history .m-title:before {width: 380px;}
	.history-box {width: auto;}
	.history-items {margin-bottom: 30px;}
	.new-years {margin-top: 50px;}
	.section.inner-numbers .m-title:before {display: none;}
	.numbers-items .numbers-col {width: 50%;}
	.numbers-items .numbers-col.small {width: 50%;}
	.numbers-items .numbers-col.medium {width: 50%;}
	.numbers-items .numbers-col.large {width: 100%;}
	.sert-carousel:before, .sert-carousel:after {left: 50%;}
	.sert-carousel:after {display: none;}
	.section.inner-sertificate .m-title {margin-bottom: 40px;}
	.sert-carousel .owl-carousel .owl-stage-outer {padding-bottom: 50px;}
	.sert-carousel .owl-prev, .sert-carousel .owl-next {left: -15px;}
	.sert-carousel .owl-next {left: auto; right: -15px;}
}

@media screen and (max-width: 740px) {
	.fw {width: 300px;}
	.wrapper {padding-top: 120px;}
	a.btn, .btn {padding: 0 20px; height: 30px; line-height: 26px; font-size: 12px;}
	.m-title {margin: 0 0 30px 0; font-size: 32px; line-height: 42px;}
	.h-title span {font-size: 15px; line-height: 25px;}
	.h-title {margin: 0 0 30px 0; padding: 0 0 10px 0; font-size: 32px; line-height: 42px;}
	.text-box h1, .text-box h2, .text-box h3, .text-box h4, .text-box h5, .text-box h6 {font-size: 26px;}
	.text-box p, .text-box ul li, .text-box ol li, .text-box blockquote, .text-box table td {font-size: 14px; line-height: 20px;}
	.circle-dots {display: none;}
	.section.m-contacts .circle-dots {display: none;}
	.c-navs {display: none;}

	/*header*/
	.header {position: fixed;}
	.header .logo {width: 145px;}
	.header .head-top {padding-top: 15px; height: 70px;}
	.header .head-right {padding-top: 0; width: 140px;}
	.header .login-btn {padding-left: 0; top: 4px; right: 0; width: 32px; height: 32px; font-size: 0; background: #4f96b0; border-radius: 32px; -webkit-border-radius: 32px;}
	.header .login-btn:before {margin: 0; left: 0; top: 0; width: 100%; height: 100%; background: url(../images/m_log.svg) no-repeat; background-size: contain;}
	.header .tel-btn {top: 4px; display: block;}
	.header .contacts {display: none;}
	.header .main-menu {height: 50px;}
	.header .menu-btn {width: 30px; height: 30px;}
	.header .main-menu .call-btn {padding: 0 15px; font-size: 10px; height: 30px; line-height: 30px;}
	.popup-menu {top: 70px;}

	/*started*/
	.started-carousel .slide-img {height: 300px;}
	.started-carousel .slide-img .slide-bg {width: 300px; height: 300px;}
	.started-carousel .slide-img .slide-bg:before {left: -280px; width: 780px;}
	.started-carousel .slide-img .slide-bg:after {display: none;}
	.started-carousel .slide-fw {padding-top: 25px; margin: 0 0 0 -150px; width: 300px;}
	.started-carousel .title {font-size: 32px; line-height: 32px;}
	.started-carousel .subtitle {margin: 0 0 15px 0; font-size: 14px; line-height: 20px;}
	.started-carousel .btn {margin-right: 8px; width: 130px;}
	.customNav {bottom: 20px;}

	/*services*/
	.service-items .service-col {width: 100%;}
	.service-item .name {font-size: 16px;}

	/*about*/
	.benefits-box {padding: 40px 0 0 0; margin-bottom: 50px;}
	.benefits-box:after {display: none;}
	.ben-items .ben-col {margin-bottom: 35px; padding: 0; width: 100%;}
	.ben-item {padding: 80px 0 0 0; font-size: 14px; line-height: 20px;}
	.ben-item:before {top: 0; width: 60px; height: 60px; background-size: 25px auto;}
	.ben-item strong {margin: 0 0 10px 0; font-size: 18px;}
	.about-box {padding: 30px 0 50px 0;}
	.about-box:before {right: -90px;}
	.about-box .desc {margin-bottom: 40px; width: auto;}
	.about-box .desc .title {margin: 0 0 15px 0; font-size: 32px; line-height: 42px;}
	.about-box .desc .text {margin: 0 0 25px 0; font-size: 14px; line-height: 20px;}
	.about-box .desc .btn {max-width: 140px;}
	.about-box .image {width: 100%;}
	.about-box .image .slide {height: 200px;}
	.about-box .customNav {bottom: -40px;}

	/*order*/
	.section.m-order {padding: 40px 0;}
	.order-box .order-form {padding: 20px;}
	.order-box .order-form:before {display: none;}
	.order-box .order-form .title {font-size: 25px; line-height: 35px;}
	.c-form .col {width: 100%;}
	.c-form .field input[type="text"], .c-form .field input[type="tel"], .c-form .field input[type="password"], .c-form .field input[type="email"], .c-form .field textarea {padding: 0 15px; height: 34px;}
	.c-form .field textarea {padding: 15px; height: 100px;}
	.c-form .info-text {float: none; width: auto; margin-bottom: 20px;}
	.c-form .submit-btn {float: none;}
	.order-box .image {display: none;}

	/*contacts*/
	.section.m-contacts {padding: 40px 0 0 0;}
	.section.m-contacts .m-title {margin-bottom: 15px;}
	.c-tab-menu ul {display: block;}
	.c-tab-menu ul li a {margin-bottom: 15px; display: block; font-size: 14px; @include border-radius(10px);}
	.c-tab-menu ul li.active a {margin-top: 0; font-size: 14px; height: 40px; line-height: 40px;}
	.c-tab-menu ul li a:before, .c-tab-menu ul li a:after {display: none;}
	.c-tab-items {margin-top: 25px; padding: 0 0 50px 0;}
	.c-tab-items:before {display: none;}
	.c-item .desc {width: auto;}
	.c-item .desc ul {margin-bottom: 25px;}
	.c-item .desc ul li {margin: 0 0 15px 0; padding: 0; font-size: 16px;}
	.c-item .desc ul li span {position: relative; text-align: left; display: block; width: auto; font-size: 16px;}
	.c-item .desc .btn {margin-left: 0;}

	/*footer*/
	.footer .f-logo {width: 165px;}
	.footer .f-left {margin-bottom: 40px; float: none;}
	.footer .f-right {float: none;}

	/*nonebox*/
	.nonebox {width: 300px; margin: 0 0 0 -150px; padding: 40px 20px 20px 20px;}
	.nonebox .title {margin: 0 0 20px 0; font-size: 22px; line-height: 22px;}

	/*inner team*/
	.team-items .team-col {padding-bottom: 20px; width: 100%;}
	.team-item {min-height: auto;}
	.team-item .desc {padding: 0 0 20px 0;}
	.team-item .desc .adr {margin-bottom: 10px; font-size: 14px;}
	.team-item .desc .name {margin: 0 0 5px 0; font-size: 20px; line-height: 26px;}
	.team-item .desc .status {font-size: 16px;}

	/*inner pricing*/
	.pricing-item .pricing-title {padding: 20px; padding-right: 40px; font-size: 16px; line-height: 26px;}
	.pricing-item .pricing-title:before {margin-left: 0; margin-bottom: -6px; left: auto; right: 20px; bottom: 50%; width: 20px; height: 12px;}
	.pricing-item .pricing-content {padding: 0 10px;}
	.pricing-item .pricing-content table, .pricing-item .pricing-content table tbody, .pricing-item .pricing-content table tr {display: block;}
	.pricing-item .pricing-content table td {padding: 15px 20px; display: block;}
	.pricing-item .pricing-content table td:last-child {padding-top: 0;}
	.pricing-item .pricing-content table td br {display: none;}

	/*inner services*/
	.services-category .list ul li a:hover {margin: 0;}
	.services-category .list ul li a:before {display: none;}
	.services-category .list, .services-category .image {float: none; width: auto;}
	.services-category .image {margin-top: 30px;}
	.services-category .list ul li a {padding: 15px 20px; font-size: 16px;}
	.section.inner-services-info {margin-top: 10px; padding: 55px 0;}
	.section.inner-services-info .services-info:before {left: -30px; top: -30px; right: -30px; bottom: -30px;}
	.section.inner-services-info .services-info .image {display: none;}

	/*inner contacts*/
	.contacts-info-items ul {margin: 0;}
	.contacts-info-item {width: 100%; margin: 0 0 20px 0; padding: 20px; min-height: auto;}
	.contacts-info-item .name {margin-bottom: 15px; font-size: 18px;}
	.contacts-info-item .info ul li {font-size: 14px;}
	.contacts-map-items .c-map {height: 340px;}
	.contacts-map-items .c-form {padding: 20px;}
	.contacts-map-items .title {font-size: 25px; line-height: 35px;}
	.contacts-map-items .title span {font-size: 14px;}

	/*inner team page*/
	.team-page .team-image {width: 100%;}
	.team-page .info .label {float: none; margin-bottom: 10px;}
	.team-page .info .btn {margin-right: 0; float: none;}
	.team-page .info, .team-page .list, .team-page .education {margin: 0 0 40px 0;}
	.team-page .education:last-child {margin: 0;}
	.section.inner-reviews {padding: 40px 0;}
	.team-reviews .titles {margin-bottom: 40px;}
	.team-reviews .titles:before {display: none;}
	.team-reviews .titles .m-title {margin-bottom: 15px;}
	.m-subtitle {margin-bottom: 15px; font-size: 16px; line-height: 26px;}
	.reviews-item {padding: 20px;}
	.reviews-item .image {width: 50px;}
	.reviews-item .desc {margin-left: 65px;}
	.reviews-item .name, .reviews-item .date {margin-right: 0; margin-bottom: 5px; display: block; font-size: 16px; line-height: 20px;}
	.reviews-item .text {margin-top: 10px; font-size: 14px; line-height: 20px;}
	.reviews-item .text br {display: none;}

	/*inner services page*/
	.services-intro .image {margin-bottom: 25px; float: none; width: 100%;}
	.services-intro .desc {float: none; width: auto;}
	.services-intro .text-box p {font-size: 14px; line-height: 20px;}
	.stage-item .image, .types-item .image, .causes-item .image {margin-bottom: 25px; float: none; width: 120px;}
	.stage-item .desc, .types-item .desc, .causes-item .desc {margin-left: 0;}
	.stage-item .text, .types-item .text, .causes-item .text {font-size: 14px; line-height: 20px;}
	.types-info .image {margin: 0 auto; position: relative; right: 0; bottom: -31px; width: 100%; height: 220px;}
	.causes-info .desc {padding: 25px 20px 15px 20px;}
	.section.inner-pricing-s {padding: 40px 0;}
	.pricing-table table td {padding: 15px 20px; display: block; width: 100%; font-size: 16px; @include border-radius(6px 6px 0 0);}
	.pricing-table table td:last-child {padding-top: 0; text-align: left; @include border-radius(0 0 6px 6px);}
	.pricing-table table td .price:before {display: none;}
	.section.inner-solution {padding: 40px 0;}
	.solution-item .image {width: 230px;}
	.solution-item .image:after {right: -20px; width: 50px; height: 50px; background-size: 22px auto;}
	.solution-item .desc {width: 230px;}
	.solution-item .text {font-size: 14px; line-height: 20px;}

	/*inner about page*/
	.interview-intro {margin-bottom: 37px; padding: 20px;}
	.interview-intro .image {float: none; width: 100%;}
	.interview-intro .title {margin-right: 0; padding: 15px 0 0 0; font-size: 16px; line-height: 24px;}
	.interview-chat ul li .chat-item {padding: 15px; margin-left: 50px; font-size: 14px; line-height: 20px; @include border-radius(14px);}
	.interview-chat ul li .chat-item .icon {left: -50px; width: 40px; height: 40px;}
	.interview-chat ul li.answer .chat-item {padding: 15px; margin-left: 0; margin-right: 50px;}
	.interview-chat ul li.answer .chat-item .icon {left: auto; right: -50px;}
	.interview-chat ul li .chat-item .icon:before {background-size: 14px auto;}
	.section.inner-history {padding: 40px 0;}
	.section.inner-history .m-title {margin-bottom: 30px;}
	.section.inner-history .m-title:before {display: none;}
	.history-items {margin-bottom: 30px;}
	.history-item {margin-top: 0; font-size: 16px; line-height: 24px;}
	.history-item .date {bottom: -10px; font-size: 18px;}
	.new-years {margin-top: 30px; padding: 15px 15px 15px 75px; display: block; font-size: 14px; line-height: 20px;}
	.new-years:after {right: -70px; border: 45px solid transparent; border-left: 25px solid #edf4f7;}
	.new-years .date {padding: 0; font-size: 28px; line-height: 48px;}
	.new-years .date:before {display: none;}
	.section.inner-numbers {padding: 40px 0 20px 0;}
	.numbers-items .numbers-col {width: 100%;}
	.numbers-items .numbers-col.small {width: 100%;}
	.numbers-items .numbers-col.medium {width: 100%;}
	.numbers-items .numbers-col.large {width: 100%;}
	.numbers-item {padding: 20px; font-size: 14px; line-height: 20px; min-height: auto; text-align: center;}
	.numbers-item strong {font-size: 32px; line-height: 42px;}
	.numbers-item br {display: none;}
	.section.inner-videos {padding: 80px 0;}
	.videos-box:before {top: -40px; bottom: -40px; right: 40px; left: -1000px;}
	.videos-box .video-img {height: 220px;}
	.videos-box .video-desc {margin: -50px 0 0 -50px; top: 50%; left: 50%; padding: 0; width: 100px; height: 100px; font-size: 0; background: none;}
	.videos-box .video-desc:before {margin-top: 0; top: 0; right: 0; width: 100%; height: 100%;}
	.sert-carousel:before, .sert-carousel:after {display: none;}
	.section.inner-sertificate {padding: 40px 0;}
	.section.inner-sertificate .m-title {margin-bottom: 30px;}
	.sert-carousel .owl-carousel .owl-stage-outer {padding-bottom: 30px;}
	.sert-carousel .owl-prev, .sert-carousel .owl-next {left: 0;}
	.sert-carousel .owl-next {left: auto; right: 0;}
	.sert-item {width: 210px;}
}
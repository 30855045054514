/*Footer*/

.footer {
	padding: 90px 0 50px 0;
	background: #153e5c;
	.f-left {
		float: left;
	}
	.f-right {
		float: right;
		&:after {
			content: '';
			position: relative;
			display: block;
			clear: both;
		}
	}
	.f-col {
		float: left;
		margin-right: 110px;
		&:last-child {
			margin-right: 0;
		}
	}
	.f-logo {
		margin-bottom: 20px;
		width: 284px;
		font-size: 0;
		img {
			max-width: 100%;
		}
	}
	.f-copy {
		font-size: 12px;
		line-height: 36px;
		color: #fff;
		a {
			color: #00e8fd;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.f-title {
		margin: 0 0 30px 0;
		font-size: 18px;
		color: #fff;
		font-weight: 600;
	}
	.f-menu {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin: 0 0 18px 0;
				a {
					font-size: 14px;
					line-height: 16px;
					color: #fff;
				}
				&.active {
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.f-contacts {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin: 0 0 12px 0;
				font-size: 12px;
				line-height: 18px;
				color: #fff;
				font-weight: 400;
				strong {
					font-size: 18px;
					line-height: 18px;
					font-weight: 400;
				}
				a {
					color: #fff;
				}
			}
		}
	}
}